import './index.css';

import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { Sp500LongOnly5QPredictionsProvider } from './contexts/Sp500LongOnly5QContext';
import { X1000P64PredictionsProvider } from './contexts/X1000P64Context';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <CollapseDrawerProvider>
    <Sp500LongOnly5QPredictionsProvider>
      <X1000P64PredictionsProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </X1000P64PredictionsProvider>
    </Sp500LongOnly5QPredictionsProvider>
  </CollapseDrawerProvider>
);
