import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';



interface Props extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<object, Props>(({ disabledLink = false, sx }, ref) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;

  const logo = (
    <Box ref={ref} sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 375">
        <defs>
          <linearGradient id="BG1" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor={PRIMARY_LIGHT} />
            <stop offset="95%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
          transform="matrix(0.3 0 -0 -0.3 0 350)"
        >
          <path
            fill="url(#BG1)"
            d="M425 1020 c-279 -56 -466 -326 -412 -593 40 -196 169 -344 350 -399 55 -16 88 -20 176 -16 103 3 112 5 192 45 83 41 165 113 224 197 l20 28 70 -141 69 -141 254 0 c140 0 252 4 250 8 -1 5 -23 52 -47 105 l-45 97 -143 0 -143 0 -77 155 -77 154 127 256 127 255 -113 0 -112 0 -71 -141 -70 -142 -33 52 c-104 162 -329 258 -516 221z m222 -226 c68 -32 113 -85 167 -192 l44 -88 -49 -95 c-58 -109 -81 -136 -152 -175 -47 -26 -62 -29 -142 -29 -74 0 -97 4 -133 23 -114 61 -175 157 -175 276 0 231 233 379 440 280z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
});

export default Logo;
