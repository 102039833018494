import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';

import DashboardLayout from '../layouts/dashboard';


const Loadable = (Component: ElementType) => (props: object) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'signals',
      element: (
        <DashboardLayout />
      ),
      children: [
        { path: 'sp500-long-only-5q', element: <SP500LongOnly5QListingPage /> },
        { path: 'sp500-long-only-5q/details/:ticker', element: <SP500LongOnly5QDetailsPage /> },
        { path: 'x1000-p64', element: <X1000P64ListingPage /> },
        { path: 'x1000-p64/details/:ticker', element: <X1000P64DetailsPage /> },
      ],
    },
    { path: '404', element: <NotFound /> },
    { path: '/', element: <Navigate to="/signals/sp500-long-only-5q" replace />},
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const SP500LongOnly5QDetailsPage = Loadable(lazy(() => import('../pages/signals/sp500-long-only-5q/DetailsPage')));
const SP500LongOnly5QListingPage = Loadable(lazy(() => import('../pages/signals/sp500-long-only-5q/ListingPage')));
const X1000P64DetailsPage = Loadable(lazy(() => import('../pages/signals/x1000-p64/DetailsPage')));
const X1000P64ListingPage = Loadable(lazy(() => import('../pages/signals/x1000-p64/ListingPage')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));