import { createContext, useEffect, useState, ReactNode } from 'react';

import { AXIOS_ } from '../utils/axios-config';
import { TickerPredictions } from '../@types/ticker-predictions';


interface X1000P64PredictionContextType {
    predictions: TickerPredictions[];
}

export const X1000P64PredictionsContext = createContext<X1000P64PredictionContextType>({ predictions: [] });

interface X1000P64PredictionsProviderProps {
    children: ReactNode;
  }
  
  export const X1000P64PredictionsProvider: React.FC<X1000P64PredictionsProviderProps> = ({ children }) => {
    const [predictions, setPredictions] = useState<TickerPredictions[]>([]);
  
    useEffect(() => {
        async function fetchData() {
          try {
            const response = await AXIOS_.get("/api/x1000-p64/predictions/v1")
            setPredictions(response.data);
          } catch (error) {
            console.log(error);
          }
        }
        if (predictions.length === 0) {
            fetchData();    
        }
      }, [predictions]);
  
    return (
      <X1000P64PredictionsContext.Provider value={{ predictions }}>
        {children}
      </X1000P64PredictionsContext.Provider>
    );
  };