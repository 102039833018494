import { createContext, useEffect, useState, ReactNode } from 'react';

import { AXIOS_ } from '../utils/axios-config';
import { TickerPredictions } from '../@types/ticker-predictions';


interface Sp500LongOnly5QPredictionContextType {
    predictions: TickerPredictions[];
}

export const Sp500LongOnly5QPredictionsContext = createContext<Sp500LongOnly5QPredictionContextType>({ predictions: [] });

interface Sp500LongOnly5QPredictionsProviderProps {
    children: ReactNode;
  }
  
  export const Sp500LongOnly5QPredictionsProvider: React.FC<Sp500LongOnly5QPredictionsProviderProps> = ({ children }) => {
    const [predictions, setPredictions] = useState<TickerPredictions[]>([]);
  
    useEffect(() => {
        async function fetchData() {
          try {
            const response = await AXIOS_.get("/api/sp500-long-only-5q/predictions/v1")
            setPredictions(response.data);
          } catch (error) {
            console.log(error);
          }
        }
        if (predictions.length === 0) {
            fetchData();    
        }
      }, [predictions]);
  
    return (
      <Sp500LongOnly5QPredictionsContext.Provider value={{ predictions }}>
        {children}
      </Sp500LongOnly5QPredictionsContext.Provider>
    );
  };