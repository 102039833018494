function path(root: string, sublink: string) {
    return `${root}${sublink}`;
  }
  
  const SIGNALS_ROOT = '/signals';
  
  export const SIGNALS_PATH = {
    root: SIGNALS_ROOT,
    signals: {
      sp500LongOnly5QListing: path(SIGNALS_ROOT, '/sp500-long-only-5q'),
      sp500LongOnly5QDetails: path(SIGNALS_ROOT, '/sp500-long-only-5q/details'),
      x1000P64Listing: path(SIGNALS_ROOT, '/x1000-p64'),
      x1000P64Details: path(SIGNALS_ROOT, '/x1000-p64/details'),
    },
  };
  