import { SIGNALS_PATH } from '../../../routes/paths';


const navConfig = [
  {
    subheader: 'Signals',
    items: [
      { title: 'S&P500 - Long Only 5Q', path: SIGNALS_PATH.signals.sp500LongOnly5QListing },
      { title: 'X1000 P64', path: SIGNALS_PATH.signals.x1000P64Listing },
    ],
  },
];

export default navConfig;
